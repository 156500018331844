import React from 'react';
import './TodosLoading.css';
import { TodoItem } from '../TodoItem';

/**
 * I must make the loading skeletons for the todo items. That is, the things that must load
 * and be esthetic while the content itself loads. 
 * AP1
 *  Use the TodoItem component, do not render any content but render a number of list item outlines. 
 *  Then animate it.
 * 
 * 
 */



function TodosLoading() {
    return (
        <>
            <TodoItem charging={true}/>
            <TodoItem charging={true}/>
            <TodoItem charging={true}/>
            <TodoItem charging={true}/>
        </>

    )
}

export { TodosLoading };