import React from 'react';
import './TodoForm.css';
import {TodoContext} from '../TodoContext';

function TodoForm(){
    const {setOpenModal, addTodo} = React.useContext(TodoContext);

    const [todoValue, setTodoValue] = React.useState('');
    console.log(todoValue);
    // Link the textarea element onChange event to the corresponding setter. 
    // Linking directly seems to store the whole event and not its text only.
    // To link elements with state, value must be linked to state variable and an event to the setter; the event SETS IT.
    const onChangeText = (event) => {
        setTodoValue(event.target.value);
    }
    const onSubmit = (event) => {
        event.preventDefault();
        if ( todoValue.trim().length === 0 ) {
            console.log("Tried to add empty todo.");

        }
        else {
            addTodo(todoValue);

        }
        setOpenModal(false);
    }
    const onCancel = () => {
        setOpenModal(false);
    }
    return(
        <form>
            <label>Crear TODO</label>
            {/* Link textarea to state */}
            <textarea placeholder="Escribe el texto de tu nuevo TODO." value={todoValue}
            onChange={onChangeText} />
            <div className="button-container">
                {/* Cancel button must be of type 'button' to avoid it submitting. */}
                <button type="button" className="button button-cancel" onClick={onCancel} >Cancelar</button>
                <button type="submit" className="button button-submit" onClick={onSubmit}>Crear</button>
            </div>
            
        </form>
    )
}

export {TodoForm};