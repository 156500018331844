import { TodoCounter } from '../TodoCounter';
import { TodoSearch } from '../TodoSearch';
import { TodoList } from '../TodoList'; /* Import paths must not have beginning nor trailing whitespaces to avoid errors.  */
import { TodoItem } from '../TodoItem';
import { TodosLoading } from '../TodosLoading';
import { TodosError } from '../TodosError';
import { TodosEmpty } from '../TodosEmpty';
import React from 'react';
import { CreateTodoButton } from '../CreateTodoButton';
import { TodoContext } from '../TodoContext';
import { Modal } from '../Modal';
import {TodoForm} from '../TodoForm';

function AppUI() {
    const {
        totalTodos,
        searchedTodos,
        completeTodo,
        deleteTodo,
        loading,
        error,
        openModal,
        setOpenModal
    } = React.useContext(TodoContext);


    return (
        /* Si se deja este div; no se borra. */
        <React.Fragment>
            <TodoCounter />
            <TodoSearch />
            {/** Inline style must be JS code (first braces) and an object (second braces) using property: 'value' notation  */}
            {/* {loading && <span style={{marginTop: '15px', fontWeight: '600', fontSize: '15px', fontFamily: 'Montserrat'}}>Cargando...</span>} */}
            {/* {error && 'Error.'} */}

            {error && <TodosError />}
            {/* {console.log({totalTodos})} */}
            {/* totalTodos is NOT AN OBJECT! That's why calling its length didn't work. Measure it as an integer. */}
            {(!loading && totalTodos == 0) && <TodosEmpty />}
            <TodoList>
                {
                    /**
                     * For this map to work, it must use parentheses, not braces. I believe it has to do with
                     * returning a value on the function. 
                     */
                    searchedTodos.map(todo => (
                        <TodoItem
                            key={todo.text}
                            text={todo.text}
                            completed={todo.completed}
                            onComplete={() => {
                                completeTodo(todo.text);
                            }}
                            onDelete={() => {
                                deleteTodo(todo.text);
                            }
                            }
                        />
                    ))
                }
                {/* Loading state. Must be inside TodoList to be well styled as items. */}
                {loading && <TodosLoading />}

            </TodoList>

            <CreateTodoButton />
            {openModal &&
                <Modal>
                    <TodoForm />
                </Modal>
            }


        </React.Fragment>


    );
}

export { AppUI }; 