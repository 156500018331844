import { AppUI } from './AppUI';
import React from 'react';
import './App.css';
import { TodoProvider } from '../TodoContext';


// const defaultTodos = [
//   { text: "Darle clases de inglés a Isabella y ayudarle a organizarse mejor.", completed: false },
//   { text: "Realizar meta-cognición de pensamientos y emociones ansiosas", completed: false },
//   { text: "Hacer la cena", completed: false },
//   { text: "Comerme el almuerzo el postre", completed: false }
// ];
function App() {
  return (
    <TodoProvider>
      <AppUI/>
    </TodoProvider>
  );
}

export default App;


/**
 * OBJECTIVE
 * Show only the todo items that meet the searching criteria. That is, the todo
 * items whose text value has any word or string section that matches the user's input
 * search value. 
 * 
 * String.match() can be used to traverse a string with an expression or another string. 
 * I have searchValue which is the state of TodoSearch's input. That is, I think, a string. 
 * 
 * I think I can use it to make a conditional rendering in each TodoItem and add a display:none 
  property to the elements whose text value does not meet the condition. Except if the
  value is empty, as it should show all items. Let's call this AP1.

  AP1
    Use string.match. 
    Add a display:none to todo-items whose text.value does not match user input. Actually, the
    input is checked to see if it is contained somehow inside the todo-item text. If the input value
    is empty or it does contain it, do not add the display:none. 

    searchValue, itemTextValue. 
    if itemTextValue.match( searchValue ) == null
      && searchValue != ''  // it does not match and searchValue isn't empty
      add display:none (use in-line styling, inserting JS braces and a JS object inside braces)

      This would happen in the TodoItem component. It uses the searchValue state and I think the
      todo text state. 

      Receive the prop, then add the style with conditional rendering, using JS and grave quotes.

      RESULT
      
      This approach worked. I had to check well my syntax for adding inline style (it was with an object) and
      had to clean input transforming it into lowercase. 

      One question is: Why is TodoItem re-renderized, if it has no value assigned to searchValue? I think
      it is because it uses a state variable, and thus wherever it is used, runs again.
 */