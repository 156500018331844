import React from "react";
/**
 * 
 * Custom Hook: starts with 'use', can access state functionality, used to abbreviate and organize logic.
 * 
 * This hook allows to save into local storage in an easier way. It returns a state variable and a corresponding
 * setter, in the form of a function that encapsulates the state's setter and the localStorage item setter.
 * 
 * It has two parts basically: Define initial value for local storage and create state and new setter function.
 * Its params, itemName must be the string aiming to Local Storage item name and initial value must be the
 * initial value of the item, in JS notation (it is stringified inside the function). 
 */
function useLocalStorage(itemName, initialValue) {
  // Create the state. This state will be returned to the caller.
  const [item, setItem] = React.useState(initialValue);
  // Loading and Error states
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  // console.log("useLocalStorage function executed");

  React.useEffect(() => {
    // console.log("Effect executed in useLocalStorage");
    // console.log("TimeOut begin");

    setTimeout(() => {
      try {
        const localStorageItem = localStorage.getItem(itemName);
        // Used to define initial value of the state. It must be either the initial value provided or the existing local storage value.
        // In that way, both are the same, synchronized.  
        let parsedItem;
        // Error First. If there is nothing at Local Storage item, define it with initial value.
        if (!localStorageItem) {
          localStorage.setItem(itemName, JSON.stringify(initialValue));
          parsedItem = initialValue;
        }
        // If there is something, the state initial value must be whatever was there, in JS. 
        else {
          parsedItem = JSON.parse(localStorageItem);
        }
        setItem(parsedItem);
        // loading has finished because the functionality has been executed.
        setLoading(false);
      } catch (error) { // There was an error, so loading is finished and error is flagged.
        setLoading(false);
        setError(true);
      }
      // console.log("TimeOut end");
    }, 2000)
  }, [])
  // Create the saver function, that encapsulates setter for state and local storage. It'll be returned to caller.
  const saveItem = (newItem) => {
    localStorage.setItem(itemName, JSON.stringify(newItem));
    setItem(newItem);
  }
  // Return the state variable as well as the new setter variable. The setter can thus be encapsulated 
  // into a function that contains both setter and other functionality.
  // 
  // Return in object notation. Return the stateful item, its saver that includes the setter,
  // the loading state and the error state. 
  // Must be received in object notation as well. And object literal aliases can be used {n:newname}.
  return {
    item,
    saveItem,
    loading,
    error
  };


}

export { useLocalStorage };