import React from 'react';
import './TodoSearch.css';
import { TodoContext } from '../TodoContext';


/* Named props must go inside braces  */
function TodoSearch() {
    // Get variables from context.
    const {searchValue, setSearchValue} = React.useContext(TodoContext)

    return(
        <input placeholder="Cortar la Cebolla" onChange={ 
            (event) => {
                setSearchValue( event.target.value );
            }
         }  
            value={searchValue}
            />
    )
}

export { TodoSearch }