import React from 'react';
import {TodoContext} from '../TodoContext';
import plusicon from './plusicon.png';
import './CreateTodoButton.css';

function CreateTodoButton() {
    const {openModal, setOpenModal} = React.useContext(TodoContext);
    return(
        <span className={`create-todo-button ${openModal && 'open'}`} onClick={ () => { setOpenModal(!openModal) } }>
            <img src={plusicon}/>
        </span>
    )
}

export {CreateTodoButton}
