import React from "react";
import { createPortal } from "react-dom";
import './Modal.css';

// Define portal as a component to use it as a component, with its props and any other features.
function Modal({children}){
    return(
        // Create the portal. Pass it first the content and secondly the target node.
        createPortal(
          <div className="modal">
            {children}
          </div>, // Don't forget the comma, it's an argument even if it is JSX.
            document.getElementById("create-todo-container")
        )
    )
}

export {Modal};