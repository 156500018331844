import './TodoCounter.css';
import React from 'react';
import { TodoContext } from '../TodoContext';

function TodoCounter() {
    const {
        completedTodos: completed,
        totalTodos: total,
    } = React.useContext(TodoContext);
    /* return utiliza parentesis regulares, no llaves. */
    return (
        <h1>
            Has completado <strong>{completed}</strong> de <strong>{total}</strong> TODOs.
        </h1>
    )
}

export { TodoCounter }

