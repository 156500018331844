import { CompleteIcon } from '../TodoIcon/CompleteIcon';
import { DeleteIcon } from '../TodoIcon/DeleteIcon';
import './TodoItem.css';

function TodoItem({ text, completed, onComplete, onDelete, charging }) {
    return (
        <li 
            className={charging && 'loading-screen'} // Add item style when charging screen (skeleton).
        >
            {!charging && // Add manipulation icons only if not charging. 
                <CompleteIcon
                    completed={completed}
                    onComplete={onComplete}
                />
            }
            { // When charging, have placeholder items for the skeleton.
                charging && 
                <>
                    <div></div> <span></span>
                </>
                
            }
            { /* Dynamic class: put all classes inside grave quotes, then 
            add the cash sign and open braces, and inside use a conditional render
            logical operation &&, which reads as "if L then R, else false".
            It renders the Rightside part of the operation, the Leftside being
            the condition, which in this case is a named prop (named because
            we defined them with name as an object in the parameter of the function.) */ }
            <p className={` ${completed && "item-completed"} `}>{text}</p>
            {!charging &&
                <DeleteIcon
                    onDelete={onDelete}
                />
            }

        </li>
    )
}

export { TodoItem }




/** Unused 
 * 
 // When clicked, re-render todos with this one completed.
<img src={completedMark} className="check" onClick={onComplete} />
 */