import React from "react";
import { useLocalStorage } from './uselocalStorage';

// A context consits of three parts: The Context itself, its Provider component property
// and its Consumer component property. Here is a context that has all of the info
// used in the TODO app. We first define the context, and then we define a
// custom provider function that returns the Provider property component (just as the context
// would provide us with) adding the functionality that we need (in this case, computing
// derivative states and searching in local storage). 

// We must then import this present component, especially the custom provider function, 
// into the App component, so that all its children can 'subscribe' or access the context.


const TodoContext = React.createContext(); // Returns Context and Provider render functions.

function TodoProvider( {children} ){
    /** 
   * Create a state. Define a nameless array with two elements: stateName and setStateName.
   * The state is linked then to the element we want and the setter to the event we want the state to be updated with.
    */
  const [searchValue, setSearchValue] = React.useState('');
  // Call local storage custom hook. It returns a state that is stored into 'todos'. 
  // Meaning it can always be referenced back using the todos variable. Same for the 
  // saveTodos.  
  const { // When receiving more than two variables creating a state, use object notation and object aliases.
    item: todos,
    saveItem: saveTodos,
    loading,
    error,
  } = useLocalStorage('TODOS_V1', []);

//   Modal logic.
  const [openModal, setOpenModal] = React.useState(false);

  /* Derivative states; calculations based on states.  */
  const completedTodos = todos.filter((todo) => todo.completed).length;
  const totalTodos = todos.length;
  /* Used to show items that meet search criteria. Run the condition on all items (array) elements,
    and create a new array with the matching elements only. Use the new array to populate
    new item components with valid data only.  */
  const searchedTodos = todos.filter(
    (todo) => {
      const todoText = todo.text.toLowerCase();
      const searchText = searchValue.toLowerCase();
      return todoText.includes(searchText);
    }
  )

  /** Complete ToDo functionality.  
   * text parameter is the key identifier, to identify the element to apply this action to.
   * Create a new todo based on the state todos array with ..., find the index of the caller item's
   * text (used for identifying) using findIndex (which takes a callback function), set such element's
   * completed property to true and then update the general todos state with its setter, passing
   * the new array of this function. 
   * 
   * I think that since everything is connected to a same array (todos), and that is a React state, 
   * it means that whatever updates it, updates all of the connected functionalities, like the counter.
  */
  const completeTodo = (text) => {
    const newTodos = [...todos];
    const todoIndex = newTodos.findIndex(
      (todo) => todo.text == text
    );
    newTodos[todoIndex].completed = true;
    // Call saveTodos, the state setter created inside this component which is created 
    // inside the custom hook and can be referenced using this function, always. 
    // It might be created into a function, but it is saved into a variable, thus
    // making it stable. 
    saveTodos(newTodos);
  }

  /** deleteTodo  */
  const deleteTodo = (text) => {
    const newTodos = [...todos];
    const todoIndex = newTodos.findIndex(
      (todo) => todo.text == text
    );
    newTodos.splice(todoIndex, 1);
    saveTodos(newTodos);
  }

    const addTodo = (text) => {
        const newTodos = [...todos];
        newTodos.push(
            {text, completed: false}
        );
        saveTodos(newTodos);

    }

    return (
        <TodoContext.Provider value={
            {
            totalTodos, 
            completedTodos,
            searchValue, 
            setSearchValue, 
            searchedTodos, 
            completeTodo, 
            deleteTodo, 
            loading, 
            error,
            openModal,
            setOpenModal,
            addTodo
        }
            }>
                {children}
        </TodoContext.Provider>
    )
}

export {TodoContext, TodoProvider};